import React, {ReactNode, useContext, useEffect, useRef, useState} from "react";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import "./InitializeScreens.css";
import hsi from "../../lib/HeartSeatInterface";
import {
  IonButton,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonFooter, IonProgressBar
} from '@ionic/react';
import ConfirmCancelModal from "../Modal/ConfirmCancelModal";
import useInitializationData from "./InitializationHook";
import usePatientData from "../PatientDetails/PatientHook";
import InitializationService from "../../services/InitializationService/InitializationService";
import AuthContext from "../Auth/AuthContext";
import useSeatSettings from "../SeatSettings/SeatSettingsHook";
import {SeatProcessEvent} from "../../types/SeatProcessEvent";
import {defaultSitTime, seatProcessCheckinCode, seatProcessRecordingCode} from "../../Refs";

interface ContainerProps {
  setBanner: any;
  clearBanner: any;
  handleFormScreenLink: any;
  handleBackToStart: any;
}


const InitializeAutoSitTimerScreen: React.FC<ContainerProps> = ({
                                                                  setBanner,
                                                                  clearBanner,
                                                                  handleFormScreenLink,
                                                                  handleBackToStart
                                                                }) => {


  useEffect(() => {
    unregisterSeatListeners();
    registerSeatListeners();
  }, []);

  const authContext = useContext(AuthContext);
  const seatSettings = useSeatSettings();
  const init = useInitializationData();
  const patient = usePatientData();
  const initService = new InitializationService(authContext);

  const [sitSessionData, setSitSessionData] = useState([{sit_session_id: 0, sit_start_time: ''}]);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [isSitTimerRunning, setIsSitTimerRunning] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [hasRecordingError, setHasRecordingError] = useState<boolean>(false);
  const [showRecordingErrorPopover, setShowRecordingErrorPopover] = useState<boolean>(false);
  const [hasTimerCompleted, setHasTimerCompleted] = useState<boolean>(false);

  /**
   * This entire section is an unfortunate JS hack to deal with accessing the current useState() values within the
   * fixed autoRecordSeatListener() callback. I couldn't find a better way. The top response on SO also recommended this.
   * I know it sucks. I'm sorry.
   */
  const isRecordingRef = useRef<boolean>();
  isRecordingRef.current = isRecording;
  const isSitTimerRunningRef = useRef<boolean>();
  isSitTimerRunningRef.current = isSitTimerRunning;
  const isUploadingRef = useRef<boolean>();
  isUploadingRef.current = isUploading;
  const hasRecordingErrorRef = useRef<boolean>();
  hasRecordingErrorRef.current = hasRecordingError;

  const recordingErrorSubheaderTxt = "The initialization sit recording was interrupted before reaching the required " +
    "4 minutes and 30 seconds. Please try again."

  const sitSuccessSubheaderText = "This sit recording meets the criteria for initialization. " +
    "The patient may stand up and remove the blood pressure cuff.";

  const registerSeatListeners = () => {
    hsi.registerProcessEventHandler(autoRecordSeatListener);
    hsi.registerProcessEventHandler(postAutoRecordingErrorSeatListener);
  }

  const unregisterSeatListeners = () => {
    hsi.unregisterAllProcessEventHandlers();
  }

  /**
   * Gets the latest init recording (the one you just recorded) then go to the initialization form screen.
   * @todo this method is duplicated here and in InitializeSitTimerManualConfirmScreen due to the init
   * @todo class var. I would prefer to keep it in one place but that would require some big changes.
   */
  const getRecording = async () => {
    // @todo discuss using contexts vs local storage
    let seatUserId = localStorage.getItem('casana-patient-id');
    let seatId = localStorage.getItem('casana-seat-id');

    if (!seatUserId || !seatId) {
      setBanner(false, 'Please select a patient and seat from Patient Details before initializing.')
      setSitSessionData([]);
    }

    initService.getLatestInitRecording(Number(seatId), Number(seatUserId)).then(async (response: any) => {

      if (!response.success) {
        console.error(response);
        setBanner(false, 'Unable to retrieve latest initialization recording');
      }

      let sitSession = response.data;
      init.data.sit_session_id = sitSession.sit_session_id;
      init.data.raw_recording_filename = sitSession.raw_recording_filename;
      init.data.firmware_version = sitSession.firmware_version;
      init.data.hardware_version = sitSession.hardware_version;
      init.data.seat_user_id = sitSession.seat_user_id;
      init.data.date_of_birth = patient.data.date_of_birth;
      init.data.sit_start_time = sitSession.sit_start_time;
      init.data.height_feet = patient.data.seat_user_calibrations?.height_feet;
      init.data.height_inches = patient.data.seat_user_calibrations?.height_inches;
      init.data.sternal_notch = patient.data.seat_user_calibrations?.sternal_notch;
    }).catch((e: any) => {
      console.error(e);
      setBanner(false, 'Error getting latest initialization recording');
      return false;
    });
  }

  /**
   * BLE callback handler for the HeartSeatInterface library.
   *
   * @param ev
   */
  const autoRecordSeatListener = (ev: SeatProcessEvent) => {

    console.debug('AUTO SEAT LISTENER', ev);

    if (hasRecordingErrorRef.current) {
      return;
    }

    if (hasRecordingBegun(ev)) {
      console.debug('BEGIN RECORDING!');
      setIsRecording(true);
      setBanner(true, 'Recording has begun. Ensure patient remains seated until the timer completes.');
      startSitTimer();
    }

    if (hasRecordingCompleted(ev)) {

      if (isSitTimerRunningRef.current) {
        console.debug('SIT TIMER STILL RUNNING!!!');
        return handleRecordingError();
      }

      console.debug('END RECORDING!');
      setIsRecording(false);
      setBanner(true, 'Recording has completed.');
    }

    if (hasUploadBegun(ev)) {
      console.debug('BEGIN UPLOADING!');
      setIsUploading(true);
      setBanner(true, 'Upload has begun.');
    }

    if (hasUploadCompleted(ev)) {
      console.debug('END UPLOADING!');
      setIsUploading(false);
      setBanner(true, 'Upload has completed.');
      return handleRecordingSuccess();
    }
  }

  /**
   * If the sitter got up too soon, listen for the seat to complete any pending tasks so clinician
   * can record again. This only does anything if hasRecordingError === true.
   *
   * @param ev
   */
  const postAutoRecordingErrorSeatListener = (ev: SeatProcessEvent) => {

    console.debug('POST AUTO ERROR SEAT LISTENER', ev);

    if (!hasRecordingErrorRef.current) {
      return;
    }

    if (hasSeatCompletedTasksAfterRecordingError(ev)) {
      console.debug('SEAT HAS COMPLETED TASKS AFTER RECORDING ERROR!');
      handleSeatReadyToRecordAfterError();
    }
  }

  const handleSeatReadyToRecordAfterError = () => {
    setBanner(true, 'Seat is ready to re-record.');
    setHasRecordingError(false);
    resetRecordingStates();
  }

  /**
   * Set state to show that an error occurred and activate the postAutoRecordingErrorSeatListener method.
   * The postAutoRecordingErrorSeatListener should reset things once it sees the seat is done uploading
   * and checking in. If this doesn't happen for any reason, reset the page after 60 seconds.
   * If hasRecordingError has already been rectified, this directive will be ignored.
   */
  const handleRecordingError = () => {
    console.debug('RECORDING ERROR');
    setIsUploading(false); // Hide upload progress element if it's displayed
    setShowRecordingErrorPopover(true);
    setHasRecordingError(true);
    resetSitTimer();
    unregisterSeatListeners();
    registerSeatListeners();
    setBanner(false, 'Please wait for seat to complete pending tasks then try again.');
  }

  const resetRecordingStates = () => {
    setIsRecording(false);
    setIsSitTimerRunning(false);
    setIsUploading(false);
  }

  const resetAllStates = () => {
    setIsRecording(false);
    setIsSitTimerRunning(false);
    setIsUploading(false);
    setShowRecordingErrorPopover(false);
    setHasRecordingError(false);
  }

  const handleTryAgain = () => {
    setShowRecordingErrorPopover(false);
  }

  const hasRecordingBegun = (ev: SeatProcessEvent): boolean => {
    return !isRecordingRef.current && ev.proc_magic === seatProcessRecordingCode && typeof ev.error === 'undefined';
  }

  const hasRecordingCompleted = (ev: SeatProcessEvent): boolean => {
    return !!isRecordingRef.current && ev.proc_magic === seatProcessRecordingCode && typeof ev.error !== 'undefined';
  }

  const hasUploadBegun = (ev: SeatProcessEvent): boolean => {
    return !isUploadingRef.current && ev.proc_magic === seatProcessCheckinCode && typeof ev.error === 'undefined';
  }

  const hasUploadCompleted = (ev: SeatProcessEvent): boolean => {
    return !!isUploadingRef.current && ev.proc_magic === seatProcessCheckinCode && typeof ev.error !== 'undefined';
  }

  const hasSeatCompletedTasksAfterRecordingError = (ev: SeatProcessEvent): boolean => {
    return ev.proc_magic === seatProcessCheckinCode && typeof ev.error !== 'undefined';
  }

  const setTime = ({remainingTime}: any): any => {

    let minutes = Math.floor(remainingTime / 60);
    let seconds = remainingTime - minutes * 60;
    let formattedSeconds = seconds >= 10 ? seconds : '0' + seconds;

    // @todo revisit logic here
    if (remainingTime === 0) {
      return (
        <div className="timer">
          <div className="value">{minutes}:{formattedSeconds}</div>
        </div>
      );
    }

    return (
      <div className="timer">
        <div className="value">{minutes}:{formattedSeconds}</div>
      </div>
    );
  };

  const handleRecordingSuccess = async () => {
    resetSitTimer();
    unregisterSeatListeners();
    await handleNext();
  }

  const handleBack = () => {
    unregisterSeatListeners();
    resetAllStates();
    handleBackToStart();
  }

  // Get the recording from the cloud and move to the initialization form.
  const handleNext = async () => {
    resetAllStates();
    await getRecording();
    handleFormScreenLink();
  }

  const [sitTimerContent, setSitTimerContent] = useState<ReactNode>(
    <CountdownCircleTimer
      isPlaying={false}
      duration={defaultSitTime}
      colors={["#6a6efe", "#6a6efe"]}
      colorsTime={[10, 0]}
      strokeWidth={4}
      onComplete={() => ({shouldRepeat: false, delay: 1})}>
      {setTime}
    </CountdownCircleTimer>);

  /**
   * For auto recording, this command will be triggered when the patient sits on the seat.
   */
  const startSitTimer = (): any => {
    setIsSitTimerRunning(true);

    try {
      setSitTimerContent(<CountdownCircleTimer
        key={0}
        isPlaying={true}
        duration={defaultSitTime}
        colors={["#6A6EFF", "#6A6EFF"]}
        colorsTime={[10, 0]}
        strokeWidth={4}
        onComplete={() => {
          setIsSitTimerRunning(false);
          setHasTimerCompleted(true);
          return {shouldRepeat: false, delay: 1}
        }}
      >
        {setTime}
      </CountdownCircleTimer>);

    } catch (error) {
      setBanner(false, 'Application error. Failed to create recording.');
      console.error('APP ERROR', error);
    }
  }

  const resetSitTimer = (): any => {

    clearBanner();

    setSitTimerContent(
      <CountdownCircleTimer
        key={1}
        isPlaying={false}
        duration={defaultSitTime}
        colors={["#6A6EFF", "#6A6EFF"]}
        colorsTime={[10, 0]}
        strokeWidth={4}
        onComplete={() => ({shouldRepeat: false, delay: 1})}>
        {setTime}
      </CountdownCircleTimer>);
  }

  const handleTimerComplete = () => {
    setHasTimerCompleted(false);
  }

  useEffect(() => {
    document.title = "Start an automatic recording";
  }, []);

  return (
    <>
      <IonCardHeader>
        <IonCardTitle>Monitor the recording</IonCardTitle>
        {isUploading ? <IonProgressBar type="indeterminate" className="upload-progress"></IonProgressBar> : null}
        {hasRecordingError ? <IonProgressBar type="indeterminate" className="error-progress" color="danger"></IonProgressBar> : null}
        <IonCardSubtitle className="m-t-20 m-b-20">The timer will begin counting down once the patient is seated on the Heart Seat.
          The patient must complete the full 4 minute 30 second sit before proceeding to the next step.
        </IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <div className="timer-wrapper">
          {sitTimerContent}
        </div>
      </IonCardContent>
      <IonCardContent className="initialize-timer">
        <div className="timer-wrapper">
          <IonButton
            className="timer-btn reset"
            disabled={isRecording || isUploading || hasRecordingError}
            onClick={resetSitTimer}>Reset
          </IonButton>
        </div>
      </IonCardContent>

      <IonFooter className="standard-container-footer">
        <IonButton
          className="btn btn-back ion-float-right"
          disabled={isRecording || isUploading || hasRecordingError}
          onClick={handleBack}>Back
        </IonButton>
      </IonFooter>
      <ConfirmCancelModal
        isOpen={showRecordingErrorPopover}
        headerText="Insufficient sit length"
        subheaderText={recordingErrorSubheaderTxt}
        onButtonAction1={handleTryAgain}
        actionButtonText1="Try Again"
        showWarningIcon={true}
      />
      <ConfirmCancelModal
        isOpen={hasTimerCompleted}
        headerText="Sit complete!"
        subheaderText={sitSuccessSubheaderText}
        onButtonAction1={handleTimerComplete}
        actionButtonText1="Continue"
        showWarningIcon={false}
        bigHeader
      />
    </>
  );

};

export default InitializeAutoSitTimerScreen;

import './PatientDetails.css';
import React, {ReactNode, useContext, useEffect, useState} from 'react';
import {IonContent, IonPage} from '@ionic/react';
import ExistingPairScreen from '../../components/PatientDetails/ExistingPairScreen';
import SeatUserId from "../../components/PatientDetails/SeatUserId";
import PatientDetailsForm from '../../components/PatientDetails/PatientDetailsForm';
import ConfirmSeatUserScreen from "../../components/PatientDetails/ConfirmSeatUserScreen";
import usePatientData from "../../components/PatientDetails/PatientHook";
import useSeatSettingsData from "../../components/SeatSettings/SeatSettingsHook";
import {useHistory} from "react-router-dom";
import {LocationData} from "../../types/LocationData";
import AppLocationContext from "../../components/Includes/AppLocationContext";

const PatientDetails: React.FC = () => {
  const patient = usePatientData();
  const seatSettings = useSeatSettingsData();
  const history = useHistory();
  const locationContext = useContext<LocationData>(AppLocationContext);

  const handleCurrentPatient = () => {
    localStorage.setItem('casana-patient-id', String(patient.data.seat_user_id));
    setPatientView(<PatientDetailsForm handleSelectIDView={handleExistingView} />);
  }

  const handleOtherPatient = () => {
    setPatientView(<SeatUserId confirmPatient={handleConfirmView} handleBackView={handleBackToWifi} />);
  }

  const handleExistingView = () => {
    setPatientView(
      <ExistingPairScreen
        handleCurrentPatient={handleCurrentPatient}
        handleOtherPatient={handleOtherPatient}
      />
    );
  }

  const handlePatientDetails = () => {
    setPatientView(<PatientDetailsForm handleSelectIDView={handleExistingView}/>);
  }

  const handleConfirmView = () => {
   setPatientView(<ConfirmSeatUserScreen handleCurrentPatient={handlePatientDetails} handleSelectView={handleSelectView}/>);
  }

  const handleSelectView = () => {
    setPatientView(<SeatUserId confirmPatient={handleConfirmView} handleBackView={handleBackToWifi} />);
  }

  const handleBackToWifi = () => {
    history.push('/wifi');
    locationContext.returnView = '/wifi'
  }


  useEffect(() => {
    document.title = "Patient Details";

    if(patient.data.seat_user_id === 0 && seatSettings.settings.assignedSeatUserId === 0) {
      setPatientView(<SeatUserId confirmPatient={handleConfirmView} handleBackView={handleBackToWifi}/>);
    } else {
      setPatientView(
        <ExistingPairScreen
          handleCurrentPatient={handleCurrentPatient}
          handleOtherPatient={handleOtherPatient}
        />
      );
    }
  }, [patient.data])

  const [patientView, setPatientView] = useState<ReactNode>(
    <ExistingPairScreen handleOtherPatient={handleOtherPatient} handleCurrentPatient={handleCurrentPatient}/>
  );

  return (
    <IonPage>
      <IonContent fullscreen>
        {patientView}
      </IonContent>
    </IonPage>
  );
};

export default PatientDetails;

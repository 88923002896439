import React, {useEffect, useContext, useState} from 'react';
import './AccountSettings.css';
import {timezones} from '../../lib/utility/timezones';

import {
  IonGrid,
  IonRow,
  IonCol,
  IonCardContent,
  IonCardTitle,
  IonContent,
  IonCard,
  IonLabel,
  IonItem,
  IonInput,
  IonNote,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonFooter,
  IonText,
  IonPage
} from '@ionic/react';

import AuthContext from "../../components/Auth/AuthContext";
import AccountSettingsService from "../../services/AccountSettings/AccountSettingsService";
import {
  useAccountSettingsData,
  useAccountSettingsPasswordData
} from "../../components/AccountSettings/AccountSettingsHook";
import {
  saveOutline,
  star,
  personOutline,
  lockClosedOutline,
  arrowForwardOutline,
  arrowBackOutline,
  mailOutline,
  person,
  mail
} from "ionicons/icons";


const AccountSettings: React.FC = () => {

  const auth = useContext(AuthContext);
  const accountSettingsService = new AccountSettingsService(auth);

  let accountSettings = useAccountSettingsData();
  let passwordSettings = useAccountSettingsPasswordData();

  let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let passwordRegex = /(?=.*[A-Z])(?=.*[a-z])(?=.*\W)[A-Za-z\W0-9]{10,}$/
  const passwordMsg = 'Password must be at least 10 characters long and contain at least one special character, one uppercase letter, and one lowercase letter';

  interface AccountSettingsResponseInterface {
    'first_name': string,
    'last_name': string,
    'email': string,
    'new_email': string | null,
    'cell_phone': string | null,
    'timezone': string,
    'enable_two_factor_auth': boolean,
  }

  const [name, setName] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [cellPhone, setCellPhone] = useState<string>('');
  const [timezone, setTimezone] = useState<string>('');
  const [enableTwoFactorAuth, setEnableTwoFactorAuth] = useState<boolean>(false);
  const [newEmail, setNewEmail] = useState<string>('');

  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [successMsg, setSuccessMsg] = useState<string>('');
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [emailVerificationToken, setEmailVerificationToken] = useState('');
  const [accountSettingsSelected, setAccountSettingsSelected] = useState<boolean>(true);

  const [isFirstNameValid, setIsFirstNameValid] = useState<boolean>(true);
  const [isLastNameValid, setIsLastNameValid] = useState<boolean>(true);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [isCellPhoneValid, setIsCellPhoneValid] = useState<boolean>(true);
  const [isTimezoneValid, setIsTimezoneValid] = useState<boolean>(true);

  const [isCurrentPasswordValid, setIsCurrentPasswordValid] = useState<boolean>(true);
  const [isNewPasswordValid, setIsNewPasswordValid] = useState<boolean>(true);
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState<boolean>(true);

  useEffect(() => {
    document.title = "Account Settings";
    if (initialLoad) {
      loadAccountSettings();
      setInitialLoad(false);
    }
  }, []);

  const loadAccountSettings = () => {
    accountSettingsService.retrieveAccountSettingsData().then(async (response: any) => {
      if (response.success) {
        refreshAccountSettingsData(response.data);
      } else {
        setError(true);
        setErrorMsg('There was an error loading account settings: ' + response.data.errors);
      }

    }).catch((error: any) => {
      setError(true);
      setErrorMsg('There was an error loading account settings: ' + JSON.stringify(error));
    });
  }

  const saveAccountSettings = () => {
    clearBanner();

    if (!formIsValid()) {
      setError(true);
      setErrorMsg('There were errors with your form submission');
      return;
    }

    accountSettingsService.updateAccountSettingsData(accountSettings.data).then(async (response: any) => {
      if (response.success) {
        setSuccess(true);
        setSuccessMsg('Account settings updated.');
        refreshAccountSettingsData(response.data);
      } else {
        setError(true);
        setErrorMsg('Error updating account settings: ' + response.data.errors);
      }
    }).catch((error: any) => {
      setError(true);
      setErrorMsg('There was an error with your request: ' + JSON.stringify(error));
    });
  };

  const refreshAccountSettingsData = (data: AccountSettingsResponseInterface) => {
    accountSettings.data.first_name = data.first_name;
    accountSettings.data.last_name = data.last_name;
    accountSettings.data.email = data.email;
    accountSettings.data.new_email = data.new_email === null ? '' : data.new_email;
    accountSettings.data.cell_phone = data.cell_phone === null ? '' : data.cell_phone;
    accountSettings.data.timezone = data.timezone;
    accountSettings.data.enable_two_factor_auth = data.enable_two_factor_auth;

    setName(`${accountSettings.data.first_name} ${accountSettings.data.last_name}`);
    setFirstName(accountSettings.data.first_name);
    setLastName(accountSettings.data.last_name);
    setEmail(accountSettings.data.email);
    setCellPhone(accountSettings.data.cell_phone);
    setTimezone(accountSettings.data.timezone);
    setEnableTwoFactorAuth(accountSettings.data.enable_two_factor_auth);
    setNewEmail(accountSettings.data.new_email);
  }

  const clearPasswordData = () => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setIsCurrentPasswordValid(true);
    setIsNewPasswordValid(true);
    setIsConfirmPasswordValid(true);
  }

  const handleFirstNameChange = async (val: string) => {
    accountSettings.data.first_name = val;

    if (val.length === 0) {
      setIsFirstNameValid(false);
    } else {
      setIsFirstNameValid(true);
    }
  }

  const handleLastNameChange = async (val: string) => {
    accountSettings.data.last_name = val;

    if (val.length === 0) {
      setIsLastNameValid(false);
    } else {
      setIsLastNameValid(true);
    }
  }

  const handleTimezoneChange = async (val: string) => {
    accountSettings.data.timezone = val;

    if (val.length === 0) {
      setIsTimezoneValid(false);
    } else {
      setIsTimezoneValid(true);
    }
  }

  const handleEnableTwoFactorAuthChange = async (val: string) => {
    accountSettings.data.enable_two_factor_auth = (val === 'true');
  }

  const handleEmailChange = async (email: string) => {
    accountSettings.data.email = email;

    if (checkEmailValid(email)) {
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
    }
  }

  const checkEmailValid = (email: string) => {
    return String(email).toLowerCase().match(emailRegex);
  };

  const handleCellPhoneChange = async (val: string) => {

    if (val === '' || val === null) {
      setIsCellPhoneValid(true);
      accountSettings.data.cell_phone = val;
      return;
    }

    let formattedCellPhone = val ? formatPhoneNumber(val) : '';
    const parsedVal = val.replace(/[^0-9]/g, '');

    if (parsedVal && formattedCellPhone) {
      accountSettings.data.cell_phone = formattedCellPhone;
    } else {
      accountSettings.data.cell_phone = '';
      setIsCellPhoneValid(false);
    }

    if (parsedVal.length === 10) {
      setIsCellPhoneValid(true);
    } else {
      setIsCellPhoneValid(false);
    }
  }

  /**
   * This also exists in PatientDetailsForm.tsx @todo place all re-used utility functions in a single class
   *
   * @param str
   */
  const formatPhoneNumber = (str: string) => {
    str = str.replace(/\D/g, '');
    let size = str.length;

    if (size > 0) {
      str = "(" + str
    }

    if (size > 3) {
      str = str.slice(0, 4) + ") " + str.slice(4, 11);
    }

    if (size > 6) {
      str = str.slice(0, 9) + "-" + str.slice(9)
    }

    return str;
  }

  const checkPassword = (val: string) => {
    return !!val.match(passwordRegex);
  }

  const handleCurrentPasswordChange = async (val: string) => {
    passwordSettings.data.current_password = val;
    setCurrentPassword(val);
    setIsCurrentPasswordValid(checkPassword(val));
  }

  const handleNewPasswordChange = async (val: string) => {
    passwordSettings.data.new_password = val;
    setNewPassword(val);
    setIsNewPasswordValid(checkPassword(val));
  }

  const handleConfirmPasswordChange = async (val: string) => {
    passwordSettings.data.confirm_password = val;
    setConfirmPassword(val);
    setIsConfirmPasswordValid(checkPassword(val));
  }

  const handleEmailVerificationTokenChange = async (val: string) => {
    setEmailVerificationToken(val);
  }

  const clearBanner = () => {
    setSuccess(false);
    setSuccessMsg('');
    setError(false);
    setErrorMsg('');
  }

  const cancelAccountSettings = () => {
    clearBanner();
    loadAccountSettings();
  };

  const cancelPassword = () => {
    clearBanner();
    clearPasswordData();
    loadAccountSettings();
    setAccountSettingsSelected(true);
  };

  const savePassword = () => {
    clearBanner();

    if (!formIsValid()) {
      setError(true);
      setErrorMsg('There were errors with your form submission');
      return;
    }

    accountSettingsService.updatePassword(passwordSettings.data).then(async (response: any) => {
      if (response.success) {
        setSuccess(true);
        setSuccessMsg('Password updated.');
        clearPasswordData();
      } else {
        setError(true);
        setErrorMsg('Error updating password: ' + response.data.errors);
      }
    }).catch((error: any) => {
      setError(true);
      setErrorMsg('Error updating password: ' + JSON.stringify(error));
    });
  };

  const verifyEmailWithToken = () => {
    clearBanner();

    accountSettingsService.verifyEmailChangeWithToken(emailVerificationToken).then(async (response: any) => {
      if (response.success) {
        setSuccess(true);
        setSuccessMsg('Your mail address has been changed');
        loadAccountSettings();
      } else {
        setError(true);
        setErrorMsg('Error changing email address: ' + response.data.errors);
      }
    }).catch((error: any) => {
      setError(true);
      setErrorMsg('Error changing email address: ' + JSON.stringify(error));
    });
  }

  const cancelEmailChange = () => {
    clearBanner();

    accountSettingsService.cancelEmailChange().then(async (response: any) => {
      if (response.success) {
        setSuccess(true);
        setSuccessMsg('Email address change has been canceled.');
        setEmailVerificationToken('');
        loadAccountSettings();
      } else {
        setError(true);
        setErrorMsg('Error changing email address: ' + response.data.errors);
      }
    }).catch((error: any) => {
      setError(true);
      setErrorMsg('Error changing email address: ' + JSON.stringify(error));
    });
  }

  const refreshEmailVerificationToken = () => {
    clearBanner();

    accountSettingsService.refreshEmailVerificationToken().then(async (response: any) => {
      if (response.success) {
        setSuccess(true);
        setSuccessMsg('A new token has been sent to your email address.');
        setEmailVerificationToken('');
        loadAccountSettings();
      } else {
        setError(true);
        setErrorMsg('Error requesting new email verification token: ' + response.data.errors);
      }
    }).catch((error: any) => {
      setError(true);
      setErrorMsg('Error requesting new email verification token: ' + JSON.stringify(error));
    });
  }

  const formIsValid = () => {
    return isFirstNameValid
      && isLastNameValid
      && isEmailValid
      && (isCellPhoneValid || (accountSettings.data.cell_phone === '' || accountSettings.data.cell_phone === null))
      && isTimezoneValid;
  }

  const clickGeneralButton = () => {
    clearBanner();
    clearPasswordData();
    setAccountSettingsSelected(true);
  }

  const clickPasswordButton = () => {
    clearBanner();
    clearPasswordData();
    setAccountSettingsSelected(false);
  }

  return (
    <IonPage>
      <IonContent id="main">
        <IonContent className="account-settings-modal-container">
          <IonCard className={error ? 'ion-show flash-message' : 'ion-hide flash-message'}>
            <IonText className="danger">
              {errorMsg}
            </IonText>
          </IonCard>
          <IonCard className={success ? 'ion-show flash-message' : 'ion-hide flash-message'}>
            <IonText className="success">
              {successMsg}
            </IonText>
          </IonCard>
        </IonContent>
        <IonGrid>
          <IonRow>
            <IonCol size-xs="1" size-md="1" className="account-settings-spacer-left-col"></IonCol>
            <IonCol size-xs="3" size-md="3" className="account-settings-left-col">
              <IonCard className="standard-container-content account-settings-button-panel">
                <span>
                  <IonIcon slot="start" ios={person} md={person} className="account-settings-title-icon"/>
                  <IonCardTitle className="account-settings-title">{name}</IonCardTitle>
                </span>
                <div
                  className={`${accountSettingsSelected && 'panel-button-enabled'} ${!accountSettingsSelected && 'panel-button-disabled'}`}
                  onClick={clickGeneralButton}>
                  <div className="panel-button-content">
                    <IonLabel>
                      <IonIcon slot="start" ios={personOutline} md={personOutline}/>
                      <span> General </span>
                      <IonIcon
                        className={
                          accountSettingsSelected
                            ? 'ion-show ion-float-right account-settings-button-arrow'
                            : 'ion-hide ion-float-right account-settings-button-arrow'
                        }
                        slot="start"
                        ios={arrowForwardOutline}
                        md={arrowForwardOutline}/>
                    </IonLabel>
                  </div>
                </div>
                <div
                  className={`${accountSettingsSelected && 'panel-button-disabled'} ${!accountSettingsSelected && 'panel-button-enabled'}`}
                  onClick={clickPasswordButton}>
                  <div className="panel-button-content">
                    <IonLabel>
                      <IonIcon slot="start" ios={lockClosedOutline} md={lockClosedOutline}/>
                      <span> Change Password </span>
                      <IonIcon
                        className={
                          !accountSettingsSelected
                            ? 'ion-show ion-float-right account-settings-button-arrow'
                            : 'ion-hide ion-float-right account-settings-button-arrow'
                        }
                        slot="start"
                        ios={arrowForwardOutline}
                        md={arrowForwardOutline}/>
                    </IonLabel>
                  </div>
                </div>
              </IonCard>
            </IonCol>
            <IonCol size-xs="8" size-md="8" className="account-settings-panel">
              <IonCard className="standard-container">

                {/* Email Verify Modal */}
                <IonCardContent className={newEmail ? 'ion-show' : 'ion-hide'}>
                  <IonCard className="account-settings-email-verification-card">
                    <div className='account-settings-email-verification-title-container'>
                    <span>
                        <IonIcon className="account-settings-email-icon" slot="start" ios={mail}
                                 md={mail}/>
                        <IonCardTitle className="account-settings-title-email-verification">
                          You have requested an email change
                        </IonCardTitle>
                    </span>
                      <p><span>Please check your email and input the verification token to confirm.</span></p>
                    </div>
                    <IonCardContent>
                      <form className="casana-form">
                        <IonGrid className="casana-form">
                          <IonRow>
                            <IonCol size="3" size-md="3" className="account-settings-email-verification-input-col">
                              <IonInput className="account-settings-email-verification-input"
                                        required={true}
                                        autofocus={true}
                                        inputmode={"text"}
                                        minlength={6}
                                        maxlength={6}
                                        type="text"
                                        debounce={300}
                                        placeholder="Token"
                                        onIonChange={(e: any) => handleEmailVerificationTokenChange(e.detail.value)}
                                        value={emailVerificationToken}
                              />
                            </IonCol>
                            <IonCol size="3" size-md="3">
                              <IonButton fill="outline"
                                         className="btn btn-type-code ion-float-right ion-text-capitalize account-settings-alt-button account-settings-email-button-cancel"
                                         onClick={cancelEmailChange}>
                                Cancel
                              </IonButton>
                            </IonCol>
                            <IonCol size="3" size-md="3">
                              <IonButton
                                fill="outline"
                                className="btn btn-type-code ion-float-right ion-text-capitalize account-settings-alt-button account-settings-email-button-refresh-token"
                                onClick={refreshEmailVerificationToken}>
                                Request New
                              </IonButton>
                            </IonCol>
                            <IonCol size="3" size-md="3">
                              <IonButton
                                className="btn btn-type-code ion-float-right ion-text-capitalize account-settings-email-button-verify"
                                onClick={verifyEmailWithToken}>
                                <IonIcon slot="start" ios={mailOutline} md={mailOutline}></IonIcon> Verify
                              </IonButton>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </form>
                    </IonCardContent>
                  </IonCard>
                </IonCardContent>

                {/* Account Settings Form */}
                <IonCardContent className={accountSettingsSelected ? 'ion-show' : 'ion-hide'}>
                  <form className="casana-form">
                    <IonGrid className="casana-form">
                      <IonRow>
                        <IonCol size="12" size-md="6">
                          <IonLabel><IonIcon className="required" slot="start" ios={star} md={star}/>First
                            name</IonLabel>
                          <IonItem
                            className={`${isFirstNameValid && 'ion-valid'} ${!isFirstNameValid && 'ion-invalid'}`}>
                            <IonInput
                              required={true}
                              autofocus={true}
                              inputmode={"text"}
                              maxlength={255}
                              type="text"
                              debounce={300}
                              placeholder="First name"
                              onIonChange={(e: any) => handleFirstNameChange(e.detail.value)}
                              value={firstName}
                            />
                            <IonNote slot="error">First name is a required field.</IonNote>
                          </IonItem>
                        </IonCol>
                        <IonCol size="12" size-md="6">
                          <IonLabel><IonIcon className="required" slot="start" ios={star} md={star}/>Last
                            name</IonLabel>
                          <IonItem
                            className={`${isLastNameValid && 'ion-valid'} ${!isLastNameValid && 'ion-invalid'}`}>
                            <IonInput
                              required={true}
                              autofocus={true}
                              inputmode={"text"}
                              maxlength={255}
                              type="text"
                              debounce={300}
                              placeholder="Last name"
                              onIonChange={(e: any) => handleLastNameChange(e.detail.value)}
                              value={lastName}
                            />
                            <IonNote slot="error">Last name is a required field.</IonNote>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="12" size-md="6">
                          <IonLabel><IonIcon className="required" slot="start" ios={star} md={star}/>Email</IonLabel>
                          <IonItem className={`${isEmailValid && 'ion-valid'} ${!isEmailValid && 'ion-invalid'}`}>
                            <IonInput
                              required={true}
                              autofocus={true}
                              inputmode={"text"}
                              maxlength={180}
                              type="text"
                              debounce={300}
                              placeholder="Email"
                              onIonChange={(e: any) => handleEmailChange(e.detail.value)}
                              value={email}
                            />
                            <IonNote slot="error">Email must be a valid email address.</IonNote>
                          </IonItem>
                        </IonCol>
                        <IonCol size="12" size-md="6">
                          <IonLabel><IonIcon/>Cell phone</IonLabel>
                          <IonItem
                            className={`${isCellPhoneValid && 'ion-valid'} ${!isCellPhoneValid && 'ion-invalid'}`}>
                            <IonInput
                              autofocus={true}
                              inputmode="numeric"
                              type="text"
                              placeholder="(555) 555-5555"
                              minlength={14}
                              maxlength={14}
                              debounce={300}
                              onIonChange={(e: any) => handleCellPhoneChange(e.detail.value)}
                              value={cellPhone}
                            />
                            <IonNote slot="error">
                              Must be 10 digits, no dashes or spaces. Do not include country code.
                            </IonNote>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="6" size-md="6">
                          <IonLabel><IonIcon className="required" slot="start" ios={star} md={star}/>Timezone</IonLabel>
                          <IonItem className="account-settings-single-line-item">
                            <IonSelect
                              placeholder="Select timezone"
                              value={timezone}
                              onIonChange={(e: any) => handleTimezoneChange(e.detail.value)}
                            >
                              {timezones.map((timezone) => {
                                return (<IonSelectOption key={timezone} value={timezone}>{timezone}</IonSelectOption>);
                              })};
                            </IonSelect>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="12" size-md="12">
                          <IonLabel>
                            <strong>Enable Two Factor Authentication</strong>
                            <p>Enabling two-factor authentication will send a security code to the email associated with your Casana account.
                              You will need this code in order to sign in to the Casana Cloud.
                            </p>
                          </IonLabel>
                          <IonItem className="account-settings-two-factor">
                            <IonSelect
                              placeholder="Select timezone"
                              value={enableTwoFactorAuth ? "true" : "false"}
                              onIonChange={(e: any) => handleEnableTwoFactorAuthChange(e.detail.value)}
                            >
                              <IonSelectOption value="true">On</IonSelectOption>
                              <IonSelectOption value="false">Off</IonSelectOption>
                            </IonSelect>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </form>
                  <IonItem></IonItem>
                  <IonFooter className="md account-settings-container-footer">
                    <IonRow>
                      <IonCol size="12" size-md="12">
                          <IonButton className="btn btn-type-code ion-float-right account-settings-alt-button account-settings-footer-button"
                                     onClick={saveAccountSettings}>
                            <IonIcon slot="start" ios={saveOutline} md={saveOutline}></IonIcon> Save
                          </IonButton>
                        <IonButton fill="outline"
                                   className="btn btn-type-code ion-float-right account-settings-alt-button account-settings-footer-button"
                                   onClick={cancelAccountSettings}>
                          <IonIcon slot="start" ios={arrowBackOutline} md={arrowBackOutline}></IonIcon> Cancel
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonFooter>
                </IonCardContent>

                {/* Password Change Form */}
                <IonCardContent className={!accountSettingsSelected ? 'ion-show' : 'ion-hide'}>
                  <form className="casana-form">
                    <IonGrid className="casana-form">
                      <IonRow>
                        <IonCol size="12" size-md="6">
                          <IonLabel><IonIcon className="required" slot="start" ios={star} md={star}/>Current
                            Password</IonLabel>
                          <IonItem
                            className={isCurrentPasswordValid ? 'account-settings-single-line-item ion-valid' : 'account-settings-single-line-item ion-invalid'}>
                            <IonInput
                              required={true}
                              autofocus={true}
                              inputmode={"text"}
                              minlength={10}
                              maxlength={255}
                              type="password"
                              debounce={300}
                              placeholder="Current password"
                              onIonChange={(e: any) => handleCurrentPasswordChange(e.detail.value)}
                              value={currentPassword}
                            />
                            <IonNote slot="error">{passwordMsg}</IonNote>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="12" size-md="6">
                          <IonLabel><IonIcon className="required" slot="start" ios={star} md={star}/>New
                            Password</IonLabel>
                          <IonItem
                            className={`${isNewPasswordValid && 'ion-valid'} ${!isNewPasswordValid && 'ion-invalid'}`}>
                            <IonInput
                              required={true}
                              autofocus={true}
                              inputmode={"text"}
                              minlength={10}
                              maxlength={255}
                              type="password"
                              debounce={300}
                              placeholder="New Password"
                              onIonChange={(e: any) => handleNewPasswordChange(e.detail.value)}
                              value={newPassword}
                            />
                            <IonNote slot="error">{passwordMsg}</IonNote>
                          </IonItem>
                        </IonCol>
                        <IonCol size="12" size-md="6">
                          <IonLabel><IonIcon className="required" slot="start" ios={star} md={star}/>Confirm
                            Password</IonLabel>
                          <IonItem
                            className={`${isConfirmPasswordValid && 'ion-valid'} ${!isConfirmPasswordValid && 'ion-invalid'}`}>
                            <IonInput
                              required={true}
                              autofocus={true}
                              inputmode={"text"}
                              minlength={10}
                              maxlength={255}
                              type="password"
                              debounce={300}
                              placeholder="Confirm Password"
                              onIonChange={(e: any) => handleConfirmPasswordChange(e.detail.value)}
                              value={confirmPassword}
                            />
                            <IonNote slot="error">{passwordMsg}</IonNote>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </form>
                  <IonItem></IonItem>
                  <IonFooter className="md account-settings-container-footer">
                    <IonRow>
                      <IonCol size="12" size-md="12">
                        <IonButton className="btn btn-type-code ion-float-right account-settings-alt-button account-settings-footer-button"
                                   onClick={savePassword}>
                          <IonIcon slot="start" ios={saveOutline} md={saveOutline}></IonIcon> Save
                        </IonButton>
                        <IonButton fill="outline"
                                   className="btn btn-type-code ion-float-right account-settings-alt-button account-settings-footer-button"
                                   onClick={cancelPassword}>
                          <IonIcon slot="start" ios={arrowBackOutline} md={arrowBackOutline}></IonIcon> Cancel
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonFooter>
                </IonCardContent>

              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default AccountSettings;

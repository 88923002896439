import React from "react";

import {
  IonButton,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonLabel,
  IonText,
  IonCol,
  IonRow,
  IonFooter
} from '@ionic/react';
import useInitializationData from "./InitializationHook";

interface ContainerProps {
  resetAndRestart: any;
  returnToInitForm: any;
}

const InitializeFormSuccessScreen: React.FC<ContainerProps> = ({
  resetAndRestart,
  returnToInitForm
}) => {

  const init = useInitializationData();

  return (
    <>
      <IonCardHeader>
        <IonCardTitle>Success!!!</IonCardTitle>
        <IonCardSubtitle className="m-t-20 m-b-20">The following initialization was successful. If you notice a mistake
          in the parameters listed below, please select the "Back" button to re-enter the cuff values and re-analyze
          the initialization.
        </IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <IonRow>
          <IonCol size="12" size-md="6">
            <IonText className="blockquote">
              <IonCardSubtitle className="bold">Initialization Date:</IonCardSubtitle>

              <IonCardSubtitle>{new Date(init.data.sit_start_time || '').toLocaleTimeString('en-us', {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true
              })}</IonCardSubtitle>

              <IonLabel><IonCardSubtitle className="bold">Initialization Parameters:</IonCardSubtitle></IonLabel>

              <IonCardSubtitle>BP1: {init.data.bp_systolic_1} / {init.data.bp_diastolic_1}</IonCardSubtitle>

              <IonCardSubtitle>BP2: {init.data.bp_systolic_2} / {init.data.bp_diastolic_2}</IonCardSubtitle>

              <IonCardSubtitle
                className="ion-padding-bottom">BP3: {init.data.bp_systolic_3} / {init.data.bp_diastolic_3}</IonCardSubtitle>

              <IonCardSubtitle>Sternal notch: {init.data.sternal_notch} in</IonCardSubtitle>

              <IonCardSubtitle>Height: {init.data.height_feet} ft {init.data.height_inches} in</IonCardSubtitle>

              <IonCardSubtitle>Age: {init.data.age} years</IonCardSubtitle>

              <IonCardSubtitle>Weight: {init.data.weight} lb</IonCardSubtitle>
            </IonText>
          </IonCol>
        </IonRow>
      </IonCardContent>
      <IonFooter className="standard-container-footer">
        <IonButton className="btn btn-back ion-float-right" onClick={resetAndRestart}>Finish</IonButton>
        <IonButton className="btn btn-next ion-float-right" onClick={returnToInitForm}>Back</IonButton>
      </IonFooter>
    </>
  );
};

export default InitializeFormSuccessScreen;

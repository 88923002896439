import './Initialize.css';
import React, {ReactNode, useContext, useEffect, useState} from 'react';
import {IonContent, IonPage, IonText, IonCard} from '@ionic/react';
import {useHistory} from "react-router-dom";
import {InitializationProvider} from "../../components/Initialize/InitializationProvider";
import InitializeIntroScreen from "../../components/Initialize/InitializeIntroScreen";
import InitializeManualSitTimerScreen from "../../components/Initialize/InitializeManualSitTimerScreen";
import InitializeAutoSitTimerScreen from "../../components/Initialize/InitializeAutoSitTimerScreen";
import InitializeExistingRecordingScreen from "../../components/Initialize/InitializeExistingRecordingScreen";
import InitializeFormScreen from "../../components/Initialize/InitializeFormScreen";
import InitializeSitTimerManualConfirmScreen from "../../components/Initialize/InitializeSitTimerManualConfirmScreen";
import InitializeFormSuccessScreen from "../../components/Initialize/InitializeFormSuccessScreen";
import InitializeFormFailureScreen from "../../components/Initialize/InitializeFormFailureScreen";
import useSeatSettings from "../../components/SeatSettings/SeatSettingsHook";
import AuthenticationService from "../../services/AuthenticationService/AuthenticationService";
import AuthContext from "../../components/Auth/AuthContext";
import hsi from "../../lib/HeartSeatInterface";
import {LocationData} from "../../types/LocationData";
import AppLocationContext from "../../components/Includes/AppLocationContext";


const Initialize: React.FC = () => {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const seatSettings = useSeatSettings();
  const authenticationService = new AuthenticationService(authContext);
  const locationContext = useContext<LocationData>(AppLocationContext);

  const [errorText, setErrorText] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [successText, setSuccessText] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);

  useEffect(() => {
    document.title = "Initialize your seat";
  }, []);

  const setBanner = (success: boolean, message: string) => {
    if (success) {
      setSuccess(true);
      setSuccessText(message);
      setError(false);
    } else {
      setError(true);
      setErrorText(message);
      setSuccess(false);
    }
  }

  const clearBanner = () => {
    setSuccess(false);
    setError(false);
  }

  const handleRecordSitScreenLink = () => {
    setInitializeView(
      <InitializeExistingRecordingScreen
        handleFormScreenLink={handleFormScreenLink}
        setBanner={setBanner}
        handleBackToStart={handleBackToStart}
      />
    )
  }

  const handleFormScreenLink = () => {
    setInitializeView(
      <InitializeFormScreen
        setBanner={setBanner}
        clearBanner={clearBanner}
        handleInitializationSuccess={handleInitializationSuccess}
        handleBackToStart={handleBackToStart}
        handleInitializationFailure={handleInitializationFailure}
      />
    )
  }

  const returnToInitForm = () => {
    clearBanner();
    setInitializeView(
      <InitializeFormScreen
        setBanner={setBanner}
        clearBanner={clearBanner}
        handleInitializationSuccess={handleInitializationSuccess}
        handleBackToStart={handleBackToStart}
        handleInitializationFailure={handleInitializationFailure}
      />
    )
  }

  const handleRecordingCompletedLinkManual = () => {
    setInitializeView(
      <InitializeSitTimerManualConfirmScreen
        handleFormScreenLink={handleFormScreenLink}
        handleCancelConfirmRecording={handleCancelConfirmRecordingManual}
        setBanner={setBanner}
      />
    )
  }

  const handleManualSitTimerLink = () => {
    setInitializeView(
      <InitializeManualSitTimerScreen
        handleRecordingCompletedLink={handleRecordingCompletedLinkManual}
        setBanner={setBanner}
        clearBanner={clearBanner}
        handleBackToStart={handleBackToStart}
      />
    )
  }

  const handleAutoSitTimerLink = () => {
    setInitializeView(
      <InitializeAutoSitTimerScreen
        setBanner={setBanner}
        clearBanner={clearBanner}
        handleFormScreenLink={handleFormScreenLink}
        handleBackToStart={handleBackToStart}
      />
    )
  }

  const handleCancelConfirmRecordingManual = () => {
    clearBanner();
    setInitializeView(
      <InitializeManualSitTimerScreen
        handleRecordingCompletedLink={handleRecordingCompletedLinkManual}
        setBanner={setBanner}
        clearBanner={clearBanner}
        handleBackToStart={handleBackToStart}
      />
    )
  }

  const handleInitializationSuccess = () => {
    setInitializeView(
      <InitializeFormSuccessScreen
        resetAndRestart={resetAndRestart}
        returnToInitForm={returnToInitForm}
      />
    )
  }

  const handleInitializationFailure = () => {
    setInitializeView(
      <InitializeFormFailureScreen
        resetAndRestart={resetAndRestart}
        handleBackToStart={handleBackToStart}
        returnToInitForm={returnToInitForm}
      />
    )
  }

  const handleBackToStart = () => {
    clearBanner();
    setInitializeView(
      <InitializeIntroScreen
        handleRecordSitScreenLink={handleRecordSitScreenLink}
        handleManualSitTimerLink={handleManualSitTimerLink}
        handleAutoSitTimerLink={handleAutoSitTimerLink}
        setBanner={setBanner}
        clearBanner={clearBanner}
      />
    )
  }

  /**
   * Clear modals, re-enable auto upload and recording for seat, remove items from local storage, and return to start
   */
  const resetAndRestart = async () => {
    clearBanner();
    await hsi.handleCmd('checkin', null); // @todo is this needed?
    localStorage.removeItem('casana-patient-id');
    localStorage.removeItem('casana-seat-id');
    history.push("/pair");
    locationContext.returnView = '/pair';
    seatSettings.setButtonClass('off');
  }

  /**
   * @todo revisit if needed
   * Clear modals, re-enable auto upload and recording for seat, remove items from local storage, then log out
   */
  const handleFinishAndLogout = async () => {

    clearBanner();
    await hsi.handleCmd('checkin', null); // @todo is this needed?
    localStorage.removeItem('casana-patient-id');
    localStorage.removeItem('casana-seat-id');

    authenticationService.logout().then(()=>{
      history.push("/login");
      window.location.reload();
    });
  }

  const [initializeView, setInitializeView] = useState<ReactNode>(
    <InitializeIntroScreen
      handleRecordSitScreenLink={handleRecordSitScreenLink}
      handleManualSitTimerLink={handleManualSitTimerLink}
      handleAutoSitTimerLink={handleAutoSitTimerLink}
      setBanner={setBanner}
      clearBanner={clearBanner}
    />
  );

  return (
    <IonPage>
      <InitializationProvider>
        <IonContent fullscreen className="container">
          <IonCard className={error ? 'ion-show flash-message' : 'ion-hide flash-message'}>
            <IonText className="danger">
              {errorText}
            </IonText>
          </IonCard>
          <IonCard className={success ? 'ion-show flash-message' : 'ion-hide flash-message'}>
            <IonText className="success">
              {successText}
            </IonText>
          </IonCard>
          <IonCard className="standard-container">
            {initializeView}
          </IonCard>
        </IonContent>
      </InitializationProvider>
    </IonPage>
  );
};

export default Initialize;


import {
  IonButton, IonContent, IonIcon, IonImg, IonItem, IonText,
  IonLabel, IonList, IonMenu, IonMenuToggle, IonThumbnail, IonFooter
} from '@ionic/react';

import {
  bluetoothOutline, bluetoothSharp, personOutline, personSharp, pulseOutline,
  pulseSharp, wifiOutline, wifiSharp, logOutOutline
} from 'ionicons/icons';

import {useLocation, useHistory} from 'react-router-dom';
import React, {useContext, useEffect, useState} from 'react';
import {Image} from '../../types/Image';
import './Menu.css';
import AuthenticationService from "../../services/AuthenticationService/AuthenticationService";
import AuthContext from "../Auth/AuthContext";
import AppLocationContext from "../Includes/AppLocationContext";
import useSeatSettings from "../SeatSettings/SeatSettingsHook";
import HelpPopup from "../Help/HelpPopup";
import AccountSettings from "../AccountSettings/AccountSettings";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: 'Pair Heart Seat',
    url: '/pair',
    iosIcon: bluetoothOutline,
    mdIcon: bluetoothSharp
  },
  {
    title: 'WiFi',
    url: '/wifi',
    iosIcon: wifiOutline,
    mdIcon: wifiSharp
  },
  {
    title: 'Patient Details',
    url: '/patient-details',
    iosIcon: personOutline,
    mdIcon: personSharp
  },
  {
    title: 'Initialize',
    url: '/initialize',
    iosIcon: pulseOutline,
    mdIcon: pulseSharp
  }
];

const Menu: React.FC = () => {

  const history = useHistory();
  const authContext = useContext(AuthContext);
  const appLocationContext = useContext(AppLocationContext);
  const location = useLocation();
  const seatSettings = useSeatSettings();
  const [menuClass, setMenuClass] = useState<string>('ion-hide');
  const casanaLogo: Image = {src: './assets/casana-logo.svg', text: 'Casana'};
  const casanaWordmark: Image = {src: './assets/casana-wordmark-white.svg', text: 'Casana'};

  useEffect(() => {
    let auth = new AuthenticationService(authContext);
    let jwt: string = authContext.jwt ?? '';
    setMenuClass(jwt !== null && jwt?.length > 0 ? '' : 'ion-hide');

    /**
     * Query local storage for the authentication state.
     */
    if (jwt === null || jwt.length === 0) {
      if (location.pathname !== '/login') {
        history.push('/login');
        appLocationContext.returnView = '/pair';
      }
    } else {
      if (location.pathname === '/login') {
        history.push('/pair');
        appLocationContext.returnView = '/pair';
      }
    }

    /**
     * We need to make sure to trigger the pulse here if it hasn't already been set up so that
     * page reloads will also set up the heartbeat pulse. We also need to make sure it is not
     * bootstrapped on the login or logout pages.
     */
    if (canPulse()) {
      window.pulseHeartbeatInterval = auth.pulse()
    }

  }, [location, history, authContext]);

  const canPulse = () => {
    return !window.pulseHeartbeatInterval
      && location.pathname !== '/login'
      && location.pathname !== '/logout'
      && location.pathname !== '/';
  }

  const handleClick = (ev: React.MouseEvent<HTMLElement>) => {
    if (ev.currentTarget) {
      appLocationContext.returnView = ev.currentTarget.getAttribute('href') ?? '/pair';
      seatSettings.setButtonClass('off');
    }
  }

  return (
    <IonMenu contentId="main" type="overlay" className={menuClass}>
      <IonContent>
        <IonThumbnail>
          <IonImg src={casanaLogo.src} alt={casanaLogo.text} className="casana-logo"/>
          <div className="casana-wordmark">
            <IonImg src={casanaWordmark.src} alt={casanaWordmark.text} className="casana-wordmark-img"/>
          </div>
        </IonThumbnail>
        <IonList className={'progress'}>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle
                className={appPage.url === '/seat-settings' ? 'progress-btn disabled' : location.pathname === appPage.url ? 'progress-btn active' : 'progress-btn'}
                key={index} autoHide={false}>
                <IonItem routerLink={appPage.url}
                         routerDirection="none"
                         lines="none"
                         detail={false}
                         onClick={(e: any) => handleClick(e)}
                >
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon}/>
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
      <IonFooter>
        <AccountSettings handleClick={handleClick}></AccountSettings>
        <HelpPopup></HelpPopup>
        <IonButton routerLink='/logout' expand="full">
          <IonIcon slot="start" ios={logOutOutline} md={logOutOutline}/>
          <IonText className="ion-text-capitalize">Logout</IonText>
        </IonButton>
      </IonFooter>
    </IonMenu>
  );
};

export default Menu;

import './PairScreens.css';
import hsi from "../../lib/HeartSeatInterface";
import React, {useContext, useState} from "react";
import PairContext from "./PairContext";
import {Image} from "../../types/Image";
import {PairData} from "../../types/PairData";
import {
  IonNote, IonButton, IonCol, IonFooter, IonGrid,
  IonIcon, IonInput, IonItem, IonRow, IonCardTitle,
  IonCardSubtitle, IonCardHeader, IonCardContent, IonAccordionGroup, IonAccordion, IonLabel, IonImg
} from "@ionic/react";
import {bluetoothOutline, chevronForwardOutline} from "ionicons/icons";
import useModalError from "../Modal/ModalHook";
import {useHistory} from "react-router-dom";
import {LocationData} from "../../types/LocationData";
import AppLocationContext from "../Includes/AppLocationContext";
import useSeatLog from "../SeatSettings/HeartSeatLogHook";
import useSeatSettings from "../SeatSettings/SeatSettingsHook";
import AuthContext from "../Auth/AuthContext";

interface ContainerProps {
  handleSerialView: Function
  toggleDebug: Function
}

const PairPinScreen: React.FC<ContainerProps> = ({toggleDebug, handleSerialView}) => {

  const hsl = useSeatLog();
  const history = useHistory();
  const bluetoothErrorhandler = useModalError();
  const seatSettings = useSeatSettings();
  const pairContext = useContext<PairData>(PairContext);
  const locationContext = useContext<LocationData>(AppLocationContext);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [pin, setPin] = useState<string>('');

  /**
   * When the pin changes in the input, strip out any non digit characters and
   * set the valid state if we have a 6 digit string. Otherwise, trigger the invalid
   * state.
   *
   * @param ev
   */
  const handlePinChange = (ev: Event) => {
    const val = (ev.target as HTMLInputElement).value;
    const parsedVal = val.replace(/[^0-9]/g, '');

    if (parsedVal) {
      setPin(val);
    } else {
      setPin('');
      setIsValid(false);
    }

    if (parsedVal.length === 6) {
      setIsValid(true);
    } else if (val.length === 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }

  /**
   * If we have a pin that we think could be valid, send it to the seat for authentication. The seat will only
   * return a response from get_ble_auth_token if a valid pin was provided. WE then trigger the bluetooth pulse to
   * ensure that connectivity with the seat is not interrupted, and then mor the user to the Wi-Fi page.
   */
  const handleBleAuth = async () => {
    if (pin.length !== 6 || !isValid) {
      setIsValid(false);
      return;
    }

    try {
      await hsi.handleCmd('ble_auth', pin);

      await hsi.handleCmd('get_ble_auth_token', null).then((response: any) => {
        if (response) {
          pairContext.ble_auth_token = response;
          hsi.unregisterAllConnectionStatusHandler();
          hsi.unregisterAllDebugHandlers();
          hsi.unregisterAllMsgErrorHandlers();

          hsi.registerConnectionStatusHandler((connStatus: string) => {
            console.debug('BLE connection status handler callback:', connStatus);
            if (connStatus === 'connecting' || connStatus === 'connected') {
              return;
            }

            toggleDebug(false);
            bluetoothErrorhandler.addError(
              'Seat Disconnected', // title
              'You are no longer connected to a Heart Seat. Please pair to a seat in order to continue.', // content
              'Pair Seat Again', // button text
              '/pair' // button location
            );
          });

          hsi.registerDebugHandler(hsl.onHsLogMsg);
          hsi.registerMsgErrorHandler(hsl.onHsLogMsg);
          toggleDebug(true);
          history.push("/wifi");
          locationContext.returnView = '/wifi';
          seatSettings.setButtonClass('off');
        }
      });
    } catch (error) {
      setIsValid(false);
      window.clearInterval(window.blePulseHeartbeatInterval);
      toggleDebug(false);
      console.error(error);
    }
  }

  const handleBack = () => {
    handleSerialView();
  }

  return (
    <>
      <IonCardHeader>
        <IonCardTitle className="m-b-20">Enter the seat PIN</IonCardTitle>
        <IonCardSubtitle className="m-t-20 m-b-20">for seat <strong
          className="ion-text-capitalize">{pairContext.serial_number}</strong></IonCardSubtitle>
        <IonCardSubtitle>You can find the 6-digit seat PIN on the seat label, located on the battery door on the bottom
          of the seat</IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent className="standard-container-content pair-container">
        <IonGrid>
          <IonRow>
            <IonCol size="6">
              <IonItem className={`${isValid && 'pin-input ion-valid'} ${!isValid && 'pin-input ion-invalid'}`}>
                <IonIcon slot="start" ios={bluetoothOutline} md={bluetoothOutline}></IonIcon>
                <IonInput className="pin-input"
                          maxlength={6}
                          placeholder="__ __ __ __ __ __"
                          type="text"
                          onIonChange={(e: any) => handlePinChange(e)}
                          value={pin}
                ></IonInput>
                <IonNote slot="error">The seat PIN is Incorrect</IonNote>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
      <IonFooter className="standard-container-footer">
        <IonButton className="btn btn-type-code ion-float-right" onClick={handleBleAuth}>
          Next
        </IonButton>
        <IonButton className="btn btn-type-code ion-float-right" onClick={handleBack}>
          Back
        </IonButton>
      </IonFooter>

    </>
  );
};

export default PairPinScreen;

import React, {useEffect, useContext, useState} from 'react';
import {
  IonItem, IonCardContent, IonCardHeader, IonCardTitle, IonCardSubtitle, IonContent,
  IonCard, IonGrid, IonRow, IonCol, IonLabel, IonNote, IonInput, IonFooter, IonButton
} from '@ionic/react';
import PatientDetailsService from "../../services/PatientDetailsService/PatientDetailsService";
import AuthContext from "../Auth/AuthContext";
import usePatientData from "./PatientHook";

interface ContainerProps {
  confirmPatient: Function;
  handleBackView: any;
}

const SeatUserId: React.FC<ContainerProps> = ({confirmPatient, handleBackView}) => {
  const patient = usePatientData();
  const authContext = useContext(AuthContext);
  const [isValid, setIsValid] = useState<boolean>(true);
  const patientDetailsService = new PatientDetailsService(authContext, patient.data);

  useEffect(() => {
    document.title = "Enter the Patient's Cloud ID.";
  }, [])

  const handleSeatUserIdChange = (ev: Event) => {
    const val = (ev.target as HTMLInputElement).value;

    if(val.length < 4) {
      return;
    }

    if (val.length >= 4){
      patient.data.seat_user_id = parseInt(val);
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }

  const handleNext = () => {
    if(isValid && patient.data.seat_user_id !== 0){
      localStorage.setItem('casana-patient-id', String(patient.data.seat_user_id));
      patientDetailsService.retrieveSeatUserDetails(patient.data.seat_user_id).then((response: any) => {
        if(response.success){
          patient.parsePatientData(response.data);
          confirmPatient()
        } else {
          setIsValid(false);
        }
      }).catch(() => {
        setIsValid(false);
      })
    }
    else {
      setIsValid(false)
    }
  }

  const handleBack = () => {
    handleBackView();
  }

  return (
    <>
      <IonContent className="container">
        <IonCard className="standard-container">
          <IonCardHeader>
            <IonCardTitle>Enter the Patient Cloud ID</IonCardTitle>
            <IonCardSubtitle className="m-t-20 m-b-20">You can find the Patient’s Casana Cloud ID in your installer notes or by contacting Casana’s support team.</IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent className="standard-container-content">
            <IonGrid className="casana-form seat-user-id-form">
              <IonRow>
                <IonCol size="12" size-md="4">
                  <IonLabel>Patient Cloud ID</IonLabel>
                  <IonItem className={`${isValid && 'ion-valid'} ${!isValid && 'ion-invalid'}`}>
                    <IonInput
                      autofocus={true}
                      type="number"
                      minlength={4}
                      placeholder="__ __ __ __"
                      onIonChange={(e: any) => handleSeatUserIdChange(e)}
                      debounce={300}
                    ></IonInput>
                    <IonNote slot="error">The Patient ID is incorrect or cannot be found.</IonNote>
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>
          <IonFooter className="standard-container-footer">
            <IonButton className="btn btn-next ion-float-right" onClick={ handleNext }>
              Next
            </IonButton>
            <IonButton className="btn btn-back ion-float-right m-r-10" onClick={ handleBack }>
              Back
            </IonButton>
          </IonFooter>
        </IonCard>
      </IonContent>
    </>
  );
};

export default SeatUserId;

import React, {useContext, useEffect, useRef, useState} from "react";
import "../Initialize/InitializeScreens.css";
import {
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonCard, IonRow, IonCol, IonProgressBar, IonAccordionGroup, IonAccordion, IonItem, IonLabel, IonGrid, IonText
} from '@ionic/react';
import {PairData} from "../../types/PairData";
import PairContext from "../Pair/PairContext";
import {Token} from "../../types/Token";
import AuthContext from "../Auth/AuthContext";
import semver from "semver/preload";
import CloudApiService from "../../services/CloudApiService/CloudApiService";
import hsi from "../../lib/HeartSeatInterface";
import {firmwareCutoffVersion} from "../../Refs";
import {SeatProcessEvent} from "../../types/SeatProcessEvent";
import {seatProcessCheckinCode} from "../../Refs";
import ConfirmCancelModal from "../Modal/ConfirmCancelModal";
import {useHistory} from "react-router-dom";
import AppLocationContext from "../Includes/AppLocationContext";
import {LocationData} from "../../types/LocationData";
import {chevronForwardOutline} from "ionicons/icons";

interface ContainerProps {
  handleRecordSitScreenLink: any;
  handleManualSitTimerLink: any;
  handleAutoSitTimerLink: any;
  setBanner: any;
  clearBanner: any;
}

const InitializeIntroScreen: React.FC<ContainerProps> = ({
                                                           handleRecordSitScreenLink,
                                                           handleManualSitTimerLink,
                                                           handleAutoSitTimerLink,
                                                           setBanner,
                                                           clearBanner
                                                         }) => {

  const history = useHistory();
  const locationContext = useContext<LocationData>(AppLocationContext);
  const auth = useContext<Token>(AuthContext);
  const ApiService = new CloudApiService(auth);
  const pairContext = useContext<PairData>(PairContext);
  console.debug('PAIR CONTEXT', pairContext);
  const [method, setMethod] = useState<string>('');
  const [isSyncingSeatConfig, setIsSyncingSeatConfig] = useState<boolean>(false);
  const [hasBleError, setHasBleError] = useState<boolean>(false);
  const seatId = Number(localStorage.getItem('casana-seat-id'));

  /**
   * JS hack to track state inside fixed callback function seatListener().
   */
  const isSyncingSeatConfigRef = useRef<boolean>();
  isSyncingSeatConfigRef.current = isSyncingSeatConfig;

  const isNewerFirmware = (): boolean => {
    return !!pairContext.firmware_version && semver.gte(pairContext.firmware_version.toString(), firmwareCutoffVersion);
  }

  const handleManual = async () => {

    if (isSyncingSeatConfig) {
      return;
    }

    clearBanner();
    setMethod('manual');
    let response = await prepareSeatForManualRecording();
    if (response.success) {
      await handleSyncAndLink('manual');
    } else {
      setBanner(false, `Error preparing seat for recording: ${response.error}`);
    }
  }

  const handleAuto = async () => {

    if (isSyncingSeatConfig) {
      return;
    }

    clearBanner();
    setMethod('auto');
    let response = await prepareSeatForAutoRecording();
    if (response.success) {
      await handleSyncAndLink('auto');
    } else {
      setBanner(false, `Error preparing seat for recording: ${response.error}`);
    }
  }

  const handleExisting = async () => {

    if (isSyncingSeatConfig) {
      return;
    }

    clearBanner();
    setMethod('existing');
    setTimeout(function () {
      handleRecordSitScreenLink();
    }, 300);
  }

  const registerSeatListener = () => {
    hsi.registerProcessEventHandler(seatIntroScreenListener);
  }

  const unregisterSeatListener = () => {
    hsi.unregisterAllProcessEventHandlers();
  }

  const hasSeatUpdatedConfig = (ev: SeatProcessEvent): boolean => {
    return ev.proc_magic === seatProcessCheckinCode && typeof ev.error !== 'undefined';
  }

  const seatIntroScreenListener = (ev: SeatProcessEvent) => {
    console.debug('INTRO SCREEN SEAT LISTENER', ev);

    if (!isSyncingSeatConfigRef.current) {
      return;
    }

    if (hasSeatUpdatedConfig(ev)) {
      console.debug('SEAT HAS CHANGED CONFIG!');
      setIsSyncingSeatConfig(false);
    }
  }

  /**
   * Move to next screen only after forcing seat check-in to update its config to prepare for auto or manual.
   *
   * @param recordingType - auto or manual
   */
  const handleSyncAndLink = async (recordingType: string) => {
    setBanner(true, 'Updating seat settings for initialization.');
    setIsSyncingSeatConfig(true);
    unregisterSeatListener();
    registerSeatListener();

    /**
     * Check-in seat and handle checkin error if it occurs
     */
    try {
      await hsi.handleCmd('checkin', null);
    } catch (e) {
      console.error(e);
      setIsSyncingSeatConfig(false);
      unregisterSeatListener();
      setHasBleError(true);
      clearBanner();
      return;
    }

    for (let i = 0; i < 60; i++) {
      console.debug('CHECKING SEAT UPDATE CONFIG STATUS');
      await new Promise((r) => setTimeout(r, 1000));

      if (!isSyncingSeatConfigRef.current) {
        unregisterSeatListener();
        clearBanner();
        if (recordingType === 'manual') {
          return handleManualSitTimerLink();
        } else {
          return handleAutoSitTimerLink();
        }
      }
    }

    setBanner(false, 'Error updating seat configuration.');
  }

  const handleTryAgain = () => {
    setHasBleError(false);
    setTimeout(function () {
      history.push('/pair');
      locationContext.returnView = '/pair';
    }, 500);
  }

  const prepareSeatForAutoRecording = async () => {
    let res = await ApiService.prepareSeatForAutoRecording(seatId, isNewerFirmware());
    console.debug('prepareSeatForAutoRecording', res);
    return res;
  }

  const prepareSeatForManualRecording = async () => {
    let res = await ApiService.prepareSeatForManualRecording(seatId, isNewerFirmware());
    console.debug('prepareSeatForManualRecording', res);
    return res;
  }

  return (
    <>
      <IonCardHeader>
        <IonCardTitle>Record an initialization sit</IonCardTitle>
        {isSyncingSeatConfig ?
          <IonProgressBar type="indeterminate" className="upload-progress"></IonProgressBar> : null}
        <IonCardSubtitle className="m-t-20 m-b-20">
          Select the method you'll use for the initialization sit recording.
        </IonCardSubtitle>
      </IonCardHeader>
      <IonRow>
        <IonCol size="12" size-md="12" className="init-intro-method-container">
          <IonCard
            className={method === 'manual' ? "init-intro-record-method-card selected" : "init-intro-record-method-card"}
            onClick={handleManual}
          >
            <IonCardTitle>Manual Recording</IonCardTitle>
            <IonCardContent className="recording-card-option-content">
              The seat will not begin the recording until you manually instruct it to do so.
            </IonCardContent>
          </IonCard>
          <IonCard
            className={method === 'auto' ? "init-intro-record-method-card selected" : "init-intro-record-method-card"}
            onClick={handleAuto}
          >
            <IonCardTitle>Automatic Recording</IonCardTitle>
            <IonCardContent className="recording-card-option-content">
              The seat will begin recording as soon as the patient sits on the seat.
            </IonCardContent>
          </IonCard>
          <IonCard
            className={method === 'existing' ? "init-intro-record-method-card selected" : "init-intro-record-method-card"}
            onClick={handleExisting}
          >
            <IonCardTitle>Use An Existing Sit</IonCardTitle>
            <IonCardContent className="recording-card-option-content">
              Select from a list of existing sit recordings that qualify for initialization.
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonAccordionGroup className="pair-accordion no-ripple">
            <IonAccordion value="first" className="no-ripple" toggleIconSlot="start" toggleIcon={chevronForwardOutline}>
              <IonItem slot="header" className="color-app">
                <IonLabel className="pair-accordion-text">Instructions for performing initialization</IonLabel>
              </IonItem>
              <IonGrid slot="content">
                <IonRow>
                  <IonText className="ion-padding">
                    Initialization allows the Heart Seat to recognize the patient and calibrates the seat to the
                    patient’s blood pressure. <br/><br/>During the sit:
                  </IonText>
                </IonRow>
                <IonRow>
                  <IonText>
                    <ul>
                      <li>The patient must wear a blood pressure cuff that has been programmed to take three separate
                        blood pressure readings.
                      </li>
                      <br/>
                      <li>The patient’s bare thighs should make full contact with the seat’s sensors.</li>
                      <br/>
                      <li>The patient should sit as still as possible.</li>
                    </ul>
                  </IonText>
                </IonRow>
              </IonGrid>
            </IonAccordion>
          </IonAccordionGroup>
        </IonCol>
      </IonRow>
      <IonCardContent className="standard-container-content">
      </IonCardContent>
      <ConfirmCancelModal
        isOpen={hasBleError}
        headerText="Connection Error"
        subheaderText="Your seat was unable to check in. Please pair again."
        onButtonAction1={handleTryAgain}
        actionButtonText1="Pair Seat"
        showWarningIcon={true}
        bigHeader
      />
    </>
  );
};

export default InitializeIntroScreen;

import {createContext} from 'react';
import {AccountSettings, PasswordSettings} from "../../types/AccountSettings";

interface AccountSettingsInterface {
  data: AccountSettings
}

interface PasswordInterface {
  data: PasswordSettings
}


export const AccountSettingsDataContext = createContext<AccountSettingsInterface>({
  'data': {
    'first_name': '',
    'last_name': '',
    'email': '',
    'new_email': '',
    'cell_phone': '',
    'timezone': '',
    'enable_two_factor_auth': false,
  }
});

export const PasswordSettingsDataContext = createContext<PasswordInterface>({
  'data': {
    'current_password': '',
    'new_password': '',
    'confirm_password': ''
  }
});

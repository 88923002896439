import React, {ReactNode, useEffect, useState} from "react";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import "./InitializeScreens.css";
import hsi from "../../lib/HeartSeatInterface";
import {
  IonButton,
  IonInput,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonFooter
} from '@ionic/react';
import {defaultSitTime} from "../../Refs";

interface ContainerProps {
  handleRecordingCompletedLink: any;
  setBanner: any;
  clearBanner: any;
  handleBackToStart: any;
}

const InitializeManualSitTimerScreen: React.FC<ContainerProps> = ({handleRecordingCompletedLink, setBanner, clearBanner, handleBackToStart}) => {

  const setTime = ({remainingTime}: any): any => {

    let minutes = Math.floor(remainingTime / 60);
    let seconds = remainingTime - minutes * 60;
    let formattedSeconds = seconds >= 10 ? seconds : '0' + seconds;

    if (remainingTime === 0) {
      return (
        <div className="timer">
          <div className="value">{minutes}:{formattedSeconds}</div>
        </div>
      );
    }

    return (
      <div className="timer">
        <div className="value">{minutes}:{formattedSeconds}</div>
      </div>
    );
  };

  const handleSitTimerCompletion = () => {
    resetSitTimer();
    onSitTimerComplete();
  }

  const [sitTimerContent, setSitTimerContent] = useState<ReactNode>(
    <CountdownCircleTimer
      isPlaying={false}
      duration={defaultSitTime}
      colors={["#6a6efe", "#6a6efe"]}
      colorsTime={[10, 0]}
      strokeWidth={4}
      onComplete={() => ({shouldRepeat: false, delay: 1})}>
      {setTime}
    </CountdownCircleTimer>);

  const startSitTimer = (): any => {

    clearBanner();

    let duration = parseInt(`${defaultSitTime}`); // Bizarre hack to make sure duration is an integer

    let hsRecConfig = {
      reason: 1,
      duration: duration
    };

    // The create_recording command will record for the time specified but will not upload due to seat auto upload configuration
    try {
      hsi.handleCmd('create_recording', hsRecConfig).then(() => {

        // @todo check bluetooth events to verify receipt of command?
        setSitTimerContent(<CountdownCircleTimer
          key={0}
          isPlaying={true}
          duration={defaultSitTime}
          colors={["#6A6EFF", "#6A6EFF"]}
          colorsTime={[10, 0]}
          strokeWidth={4}
          onComplete={() => {
            handleSitTimerCompletion()
            return {shouldRepeat: false, delay: 1}
          }}
        >
          {setTime}
        </CountdownCircleTimer>);

      }).catch((e: any) => {
        setBanner(false, 'Bluetooth error. Failed to create recording. Please pair your seat via bluetooth before proceeding.');
        console.error('BLUETOOTH ERROR', e);
      });

    } catch (error) {
      setBanner(false, 'Application error. Failed to create recording.');
      console.error('APP ERROR', error);
    }
  }

  const resetSitTimer = (): any => {

    clearBanner();

    setSitTimerContent(<CountdownCircleTimer
      key={1}
      isPlaying={false}
      duration={defaultSitTime}
      colors={["#6A6EFF", "#6A6EFF"]}
      colorsTime={[10, 0]}
      strokeWidth={4}
      onComplete={() => ({shouldRepeat: false, delay: 1})}>
      {setTime}
    </CountdownCircleTimer>);
  }

  const onSitTimerComplete = () => {
    setBanner(true, 'Sit recording completed successfully!');
    handleRecordingCompletedLink();
  }

  useEffect(() => {
    document.title = "Start a manual recording";
  }, []);

  return (
    <>
      <IonCardHeader>
        <IonCardTitle>Start a manual recording</IonCardTitle>
        <IonCardSubtitle className="m-t-20 m-b-20">Initialization recordings must be a minimum of 270 seconds (4m30s).
        </IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <div className="timer-wrapper">
          {sitTimerContent}
        </div>
      </IonCardContent>
      <IonCardContent className="initialize-timer">
        <div className="timer-wrapper">
          <IonButton className="timer-btn start" onClick={startSitTimer}>Start</IonButton>
          <IonButton className="timer-btn reset" onClick={resetSitTimer}>Reset</IonButton>
        </div>
      </IonCardContent>

      <IonFooter className="standard-container-footer">
        <IonButton className="btn btn-back ion-float-right" onClick={handleBackToStart}>Back</IonButton>
      </IonFooter>
    </>
  );

};

export default InitializeManualSitTimerScreen;

import React, {useContext, useEffect, useState} from "react";
import InitializationService from "../../services/InitializationService/InitializationService";
import AuthContext from "../Auth/AuthContext";
import '../Initialize/InitializeScreens.css';

import {
  IonCardSubtitle,
  IonCardTitle,
  IonCardHeader,
  IonList,
  IonItem,
  IonLabel,
  IonCardContent,
  IonButton,
  IonFooter
} from '@ionic/react';
import usePatientData from "../PatientDetails/PatientHook";
import useInitializationData from "./InitializationHook";
import {SitSession} from "../../types/SitSession";

interface ContainerProps {
  handleFormScreenLink: any;
  setBanner: any;
  handleBackToStart: any;
}

const InitializeExistingRecordingScreen: React.FC<ContainerProps> = ({handleFormScreenLink, setBanner, handleBackToStart}) => {

  const init = useInitializationData();
  const patient = usePatientData();

  const authContext = useContext(AuthContext);
  const initService = new InitializationService(authContext);
  const [sitSessionData, setSitSessionData] = useState([{
    sit_session_id: 0,
    sit_start_time: '',
    raw_recording_filename: '',
    firmware_version: '',
    hardware_version: '',
    seat_user_id: 0,
  }]);

  const selectSitSession = (sitSession: SitSession): any => {
    init.data.sit_session_id = sitSession.sit_session_id;
    init.data.raw_recording_filename = sitSession.raw_recording_filename;
    init.data.firmware_version = sitSession.firmware_version;
    init.data.hardware_version = sitSession.hardware_version;
    init.data.seat_user_id = sitSession.seat_user_id;
    init.data.date_of_birth = patient.data.date_of_birth || '2000-01-01';
    init.data.sit_start_time = sitSession.sit_start_time;
    init.data.height_feet = patient.data.seat_user_calibrations.height_feet;
    init.data.height_inches = patient.data.seat_user_calibrations.height_inches;
    init.data.sternal_notch = patient.data.seat_user_calibrations?.sternal_notch;

    handleFormScreenLink();
  }

  const formatSitDateTime = (sitDateTime: string): string => {
    return new Date(sitDateTime).toLocaleTimeString('en-us', {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true
    });
  }

  useEffect(() => {
    // @todo discuss using contexts vs local storage
    let seatUserId = localStorage.getItem('casana-patient-id');
    let seatId = localStorage.getItem('casana-seat-id');

    if (!seatUserId || !seatId) {
      setBanner(false, 'Please select a patient and seat from Patient Details before initializing.')
      setSitSessionData([]);
      return;
    }

    initService.getInitRecordings(Number(seatId), Number(seatUserId)).then(async (response: any) => {

      if (!response.success) {
        return;
      }

      setSitSessionData(response.data);

    }).catch((error: any) => {
      console.error(error);
    });

  }, [])

  return (
    <>
      <IonCardHeader>
        <IonCardTitle>Select a recent recording</IonCardTitle>
        <IonCardSubtitle className="m-t-20 m-b-20">Sits that meet the 4.5-minute criteria for initialization are listed
          below. Select a recording to continue.
        </IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        {sitSessionData.length > 0 && <IonList className="sit-session-list">
          {sitSessionData.map(sitSession => {
            return (
              <IonItem key={sitSession.sit_session_id}>
                <IonLabel className="initialize-link" onClick={() => {
                  selectSitSession(sitSession)
                }}>
                  {formatSitDateTime(sitSession.sit_start_time) + ' >'}
                </IonLabel>
              </IonItem>
            );
          })}
          {/* Figure out why this IonItem below needs to be here or the whole list disappears */}
          <IonItem></IonItem>
        </IonList>}
        {!sitSessionData.length && <IonList className="sit-session-list">
          <IonCardSubtitle className="bold">No recordings found, please click "back" and make a new
            recording</IonCardSubtitle>
        </IonList>}
      </IonCardContent>
      <IonFooter className="standard-container-footer">
        <IonButton className="btn btn-back ion-float-right" onClick={handleBackToStart}>Back</IonButton>
      </IonFooter>
    </>
  );
};

export default InitializeExistingRecordingScreen;

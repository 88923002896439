import {
  IonContent, IonIcon, IonItem, IonLabel, IonPopover
} from '@ionic/react';
import React from "react";
import {Image} from "../../types/Image";
import "../Help/HelpPopup.css";
import {
  callOutline,
  helpOutline,
  helpSharp,
  mailOutline,
} from "ionicons/icons";

const helpPopupLogoImg: Image = {
  src: './assets/casana-logo.svg',
  text: 'Empowering the world’s caregivers with access to reliable, consistent vital signs to improve the lives of those we love.'
};

const HelpPopup: React.FC = () => {

  return (
    <div>
      <a id="click-trigger" className="help-popover-anchor">
        <IonItem lines="none" className="help-popover-item-container">
          <IonIcon slot="start" className="help-icon" ios={helpOutline} md={helpSharp}></IonIcon>
          <IonLabel className="help-popover-label">Help</IonLabel>
        </IonItem>
      </a>

      <IonPopover trigger="click-trigger" triggerAction="click" className="help-popover">
        <IonContent>
          <div className="help-popover-content">
            <div className="help-popover-head">
              <div className="help-popover-logo-container">
                <img className="help-popover-logo-img" src={helpPopupLogoImg.src} alt={helpPopupLogoImg.text}/>
              </div>
              <div className="help-popover-contact-info">
                <div className="help-popover-line-item">
                  <span className="help-popover-contact-us">Contact Us</span>
                </div>
                <div className="help-popover-line-item">
                  <IonIcon slot="start" className="help-popover-contact-img" ios={mailOutline}
                           md={mailOutline}></IonIcon>
                  <span> support@casanacare.com</span>
                </div>
                <div className="help-popover-line-item">
                  <IonIcon slot="start" className="help-popover-contact-img" ios={callOutline}
                           md={callOutline}></IonIcon>
                  <span> 585-949-6549</span>
                </div>
              </div>
            </div>
            <div className="help-popover-link-container">
              <a href="https://casanacare.com/faq/" target="_blank" className="help-popover-link" rel="noreferrer">
                <div className="help-popover-line">
                  <span>Frequently Asked Questions</span>
                </div>
              </a>
              <a href="https://casanacare.com/privacy-policy/" target="_blank" className="help-popover-link"
                 rel="noreferrer">
                <div className="help-popover-line">
                  <span>Privacy Policy</span>
                </div>
              </a>
              <a href="https://casanacare.com/terms-of-use/" target="_blank" className="help-popover-link"
                 rel="noreferrer">
                <div className="help-popover-line">
                  <span>Terms of Use</span>
                </div>
              </a>
            </div>
          </div>
        </IonContent>
      </IonPopover>
    </div>
  );
};

export default HelpPopup;

/**
 * Firmware versions at or after this version return wifi lists, scrub wifi passwords
 * (and seem to not update checkin time when checkin_with_upload is called).
 */
export const firmwareCutoffVersion = '0.24.0';

/**
 * Minimum sit time in seconds for a valid initialization.
 */
export const defaultSitTime = 270;

/**
 * Seat Process events for event listeners
 */
export const seatProcessRecordingCode = 0x4552;
export const seatProcessCheckinCode = 0x4943;
export const seatProcessFwDownloadCode = 0x4446;
export const seatProcessFwCheckCode = 0x4346;
export const seatProcessDirCheckCode = 0x4344;

import axios from 'axios';
import Service from '../Service/Service';
import {AccountSettings, PasswordSettings} from "../../types/AccountSettings";
import {
  useAccountSettingsData,
  useAccountSettingsPasswordData
} from "../../components/AccountSettings/AccountSettingsHook";

export default class AccountSettingsService extends Service {

  private casanaCloudApiUrl: string;
  public accountSettings = useAccountSettingsData();
  public passwordSettings = useAccountSettingsPasswordData();

  constructor(authContext: any) {
    super(authContext);
    this.casanaCloudApiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/`;
  }

  /**
   * Connect to the Casana Cloud to retrieve the logged-in user's account information
   **/
  public retrieveAccountSettingsData(): any {

    let config = {
      headers: {
        'Accept': this.token.headers?.accept,
        'Authorization': this.token.headers?.bearer
      }
    }

    return axios.get(`${this.casanaCloudApiUrl}user/account-settings`, config).then((response) => {

        return {
          success: true,
          data: response.data
        }
      }
    )
      .catch(function (error) {
        return {
          success: false,
          data: {
            errors: error.statusText,
            status_code: error.status
          }
        }
      });
  }

  /**
   * Update the currently logged-in User entity on the Casana Cloud via the API.
   */
  public updateAccountSettingsData(accountSettings: AccountSettings): any {
    let config = {
      headers: {
        'Accept': this.token.headers?.accept,
        'Content-Type': this.token.headers?.accept,
        'Authorization': this.token.headers?.bearer
      }
    }

    let data: any = {
      "first_name": accountSettings.first_name,
      "last_name": accountSettings.last_name,
      "email": accountSettings.email,
      "cell_phone": accountSettings.cell_phone,
      "timezone": accountSettings.timezone,
      "enable_two_factor_auth": accountSettings.enable_two_factor_auth
    }

    return axios.patch(`${this.casanaCloudApiUrl}user/account-settings`, data, config).then(
      (response) => {
        return {
          success: true,
          data: response.data
        }
      })
      .catch(function (error) {
        return {
          success: false,
          data: {
            errors: error.response.data,
            status_code: error.response.status
          }
        }
      });
  }

  /**
   * Update the currently logged-in User's password on the Casana Cloud via the API.
   */
  public updatePassword(passwordSettings: PasswordSettings): any {
    let config = {
      headers: {
        'Accept': this.token.headers?.accept,
        'Content-Type': this.token.headers?.accept,
        'Authorization': this.token.headers?.bearer
      }
    }

    let data: any = {
      "current_password": passwordSettings.current_password,
      "new_password": passwordSettings.new_password,
      "confirm_password": passwordSettings.confirm_password
    }

    return axios.patch(`${this.casanaCloudApiUrl}user/update-password`, data, config).then(
      (response) => {
        return {
          success: true
        }
      })
      .catch(function (error) {
        return {
          success: false,
          data: {
            errors: error.response.data,
            status_code: error.response.status
          }
        }
      });
  }

  /**
   * Update the currently logged-in User's email if the token provided is correct.
   */
  public verifyEmailChangeWithToken(token: string): any {
    let config = {
      headers: {
        'Accept': this.token.headers?.accept,
        'Content-Type': this.token.headers?.accept,
        'Authorization': this.token.headers?.bearer
      }
    }

    let data: any = {
      "token": token
    }

    return axios.post(`${this.casanaCloudApiUrl}user/verify-email`, data, config).then(
      (response) => {
        return {
          success: true
        }
      })
      .catch(function (error) {
        return {
          success: false,
          data: {
            errors: error.response.data,
            status_code: error.response.status
          }
        }
      });
  }

  public cancelEmailChange(): any {
    let config = {
      headers: {
        'Accept': this.token.headers?.accept,
        'Content-Type': this.token.headers?.accept,
        'Authorization': this.token.headers?.bearer
      }
    }

    return axios.post(`${this.casanaCloudApiUrl}user/cancel-email-change`, {}, config).then(
      (response) => {
        return {
          success: true
        }
      })
      .catch(function (error) {
        return {
          success: false,
          data: {
            errors: error.response.data,
            status_code: error.response.status
          }
        }
      });
  }

  public refreshEmailVerificationToken(): any {
    let config = {
      headers: {
        'Accept': this.token.headers?.accept,
        'Content-Type': this.token.headers?.accept,
        'Authorization': this.token.headers?.bearer
      }
    }

    return axios.post(`${this.casanaCloudApiUrl}user/refresh-email-verification-token`, {}, config).then(
      (response) => {
        return {
          success: true
        }
      })
      .catch(function (error) {
        return {
          success: false,
          data: {
            errors: error.response.data,
            status_code: error.response.status
          }
        }
      });
  }

}

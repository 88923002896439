import React, {useEffect, useContext, useState} from 'react';
import './PatientDetailsScreens.css';

import {
  IonText, IonGrid, IonRow, IonCol,
  IonCardContent, IonCardHeader, IonCardSubtitle,
  IonCardTitle, IonContent, IonCard
} from '@ionic/react';
import AuthContext from "../Auth/AuthContext";
import PatientDetailsService from "../../services/PatientDetailsService/PatientDetailsService";
import usePatientData from "./PatientHook";
import useSeatSettingsData from "../SeatSettings/SeatSettingsHook";

interface ContainerProps {
  handleOtherPatient: any;
  handleCurrentPatient: any;
}

const ExistingPairScreen: React.FC<ContainerProps> = ({handleOtherPatient, handleCurrentPatient}) => {
  const patient = usePatientData();
  const seatSettings = useSeatSettingsData();
  const [name, setName] = useState(seatSettings.settings.assignedSeatUserName)
  const [seatUserId, setSeatUserId] = useState(seatSettings.settings.assignedSeatUserId)
  const auth = useContext(AuthContext);
  const patientDetailsService = new PatientDetailsService(auth, patient.data)

  useEffect(() => {
    document.title = "Patient Details";

    if (patientDetailsService.patientID) {
      patientDetailsService.retrieveSeatUserDetails(patientDetailsService.patientID).then(async (response: any) => {
        patient.parsePatientData(response.data);
        setName(patient.data.first_name + ' ' + patient.data.last_name);
        setSeatUserId(patient.data.seat_user_id);
      });
    }

  }, [patient.data, seatSettings.settings]);

  const pairCurrentPatient = () => {
    handleCurrentPatient()
  }

  const pairOtherPatient = () => {
    handleOtherPatient()
  }

  return (
    <>
      <IonContent className="container">
        <IonCard className="standard-container">
          <IonCardHeader>
            <IonCardTitle className="m-b-20">Pair the seat with a patient</IonCardTitle>
            <IonCardSubtitle className="m-t-20 m-b-20">Select to keep the seat paired with the current patient, or pair the seat with a different patient</IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent className="standard-container-content">
            <IonGrid className="seat-user-id-form">
              <IonRow>
                <IonCol size="6" size-sm="6">
                  <IonCardContent className="seat-user-confirmation currently-paired-patient" onClick={ pairCurrentPatient }>
                    <IonText className="helper-text">Current patient</IonText>
                    <IonText className="patient-name">{ name }</IonText>
                    <IonText>[{seatUserId}]</IonText>
                  </IonCardContent>
                </IonCol>
                <IonCol size="6" size-sm="6">
                  <IonCardContent className="seat-user-confirmation pair-different-patient" onClick={ pairOtherPatient }>
                    <IonText className="helper-text">Different patient</IonText>
                  </IonCardContent>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </>
  );
};

export default ExistingPairScreen;

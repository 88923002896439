import axios from 'axios';
import Service from '../Service/Service';

export default class InitializationService extends Service {

  private getInitRecordingsURL: string;
  private getLatestInitRecordingURL: string;
  private submitInitURL: string;
  private getInitStatusURL: string;

  constructor(authContext: any) {
    super(authContext);
    this.getInitRecordingsURL = `${process.env.REACT_APP_BASE_URL}/api/v1/initialization/seat`;
    this.getLatestInitRecordingURL = `${process.env.REACT_APP_BASE_URL}/api/v1/initialization/seat/latest`;
    this.submitInitURL = `${process.env.REACT_APP_BASE_URL}/api/v1/initialization/submit`;
    this.getInitStatusURL = `${process.env.REACT_APP_BASE_URL}/api/v1/initialization/status`;
  }

  /**
   * Get recent eligible recordings by seat user and seat
   *
   * @param seatId
   * @param seatUserId
   */
  public getInitRecordings(seatId: number, seatUserId: number): any {

    let config = {
      headers: {
        'Accept': this.token.headers?.accept,
        'Authorization': this.token.headers?.bearer
      }
    }

    return axios.get(`${this.getInitRecordingsURL}/${seatId}/${seatUserId}`, config).then((response) => {
      return {success: true, data: response.data}
    }).catch(function (error) {
      return {success: false}
    });
  }

  /**
   * Get recent eligible recordings by seat user and seat
   *
   * @param seatId
   * @param seatUserId
   */
  public getLatestInitRecording(seatId: number, seatUserId: number): any {

    let config = {
      headers: {
        'Accept': this.token.headers?.accept,
        'Authorization': this.token.headers?.bearer
      }
    }

    return axios.get(`${this.getLatestInitRecordingURL}/${seatId}/${seatUserId}`, config).then((response) => {
      return {success: true, data: response.data}
    }).catch(function (error) {
      return {success: false}
    });
  }

  /**
   *
   * @param postData
   */
  public submitInit(postData: object): any {

    let config = {
      headers: {
        'Accept': this.token.headers?.accept,
        'Authorization': this.token.headers?.bearer
      }
    }

    return axios.post(this.submitInitURL, postData, config).then((response) => {
      return {success: true, data: response.data}
    }).catch(function (error) {
      return {success: false, error: error.response.data}
    });
  }

  public getInitStatus(initToken: string) {

    let config = {
      headers: {
        'Accept': this.token.headers?.accept,
        'Authorization': this.token.headers?.bearer
      }
    }

    let data = {init_token: initToken}

    return axios.post(this.getInitStatusURL, data, config).then((response) => {

      /**
       * Account for discrepant Cloud V1 API responses with errors array. If there is one error it is a list, which will
       * work, but if more than one error it is an associative array which will choke SW if not converted to a list.
       * This peculiarity will NOT be fixed on the Cloud V1 side, only here.
       */
      let formattedErrors;

      if (response.data.errors && response.data.errors.constructor === Object) {
        formattedErrors = Object.values(response.data.errors);
      } else {
        formattedErrors = response.data.errors;
      }

      return response.data.status === 'success' ? {status: 'success', errors: []} : {status: 'failed', errors: formattedErrors};

    }).catch(function (error) {
      if (error.response.status === 409) {
        return {status: 'pending', errors: []};
      }
        console.error(error);
        return {status: 'failed', errors: error}
    });
  }

}

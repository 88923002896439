import Service from '../Service/Service';
import axios from "axios";
import {SeatConfiguration} from "../../types/SeatConfiguration";

export default class CloudApiService extends Service {

  private casanaCloudApiUrl: string;

  constructor(authContext: any) {
    super(authContext);
    this.casanaCloudApiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/setup-wizard/`;
  }

  /**
   * Retrieve seat details from the Cloud API via the seat's serial number.
   *
   * @param serialNumber
   */
  public getSeat(serialNumber: string): any {
    let config = {
      headers: {
        'Accept': this.token.headers?.accept,
        'Authorization': this.token.headers?.bearer
      }
    }

    return axios.get(this.casanaCloudApiUrl + 'seat/' + serialNumber, config).then((response) => {
      return {
        current_battery_level: response.data.current_battery_level,
        serial_number: response.data.serial_number,
        firmware_version: response.data.firmware_version,
        hardware_version: response.data.hardware_version,
        seat_id: response.data.seat_id,
        target_firmware: response.data.target_firmware,
        ble_auth_token: response.data.ble_auth_token,
        assigned_users: response.data.assigned_users,
        assigned_seat_user_name: this.extractSeatUserDetail(response.data.assigned_seat_users, 'name'),
        assigned_seat_user_id: this.extractSeatUserDetail(response.data.assigned_seat_users, 'id'),
        seat_configuration: response.data.seat_configuration
      }
    })
    .catch(function (error) {
      console.error(error);
    });
  }

  /**
   * Update the Seat entity on the Casana Cloud via the API and turn off auto recording and auto uploads.
   * This also sets the seat_status to 3 (active, updating) to mark that Setup Wizard is in progress.
   *
   * @param seatId
   * @param isNewFirmware
   */
  public prepareSeatForAutoRecording(seatId: number, isNewFirmware: boolean): any{
    let config = this.getPostPutPatchConfig();
    let data;

    if (isNewFirmware) {
      data = {
        upload_interval: 0,
        automatic_recording: true,
        seat_status: 3
      }
    } else {
      data = {
        automatic_recording: true,
        automatic_upload: true,
        seat_status: 3
      }
    }

    return axios.put(this.casanaCloudApiUrl + 'seatconfig/' + seatId, data, config).then(() => {
      return {
        success: true,
      }
    })
      .catch(function (error) {
        console.error(error);
        return {
          success: false,
        }
      });
  }

  /**
   * Update the Seat entity on the Casana Cloud via the API and turn off auto recording and auto uploads.
   * This also sets the seat_status to 3 (active, updating) to mark that Setup Wizard is in progress.
   *
   * @param seatId
   * @param isNewFirmware
   */
  public prepareSeatForManualRecording(seatId: number, isNewFirmware: boolean): any{
    let config = this.getPostPutPatchConfig();
    let data;

    if (isNewFirmware) {
      data = {
        upload_interval: 14400,
        automatic_recording: false,
        seat_status: 3
      }
    } else {
      data = {
        automatic_recording: false,
        automatic_upload: false,
        seat_status: 3
      }
    }

    return axios.put(this.casanaCloudApiUrl + 'seatconfig/' + seatId, data, config).then((res) => {
      return {
        success: true,
      }
    })
      .catch(function (error) {
        console.error('ERROR!!!', error.response.data);
        return {
          success: false,
          error: error.response.data
        }
      });
  }

  /**
   * Update the Seat entity on the Casana Cloud via the API and turn on auto recording and auto uploads.
   * This occurs after completing the initialization process or upon logout. The seat_status is reset
   * to 1 (active) since disableAutoRecordingAndUpload() sets seat_status to 3 (active, updating)
   * when seats are being configured and initialized via Setup Wizard.
   *
   * @param seatId
   * @param seatConfiguration
   */
  public revertSeatConfiguration(seatId: string, seatConfiguration?: SeatConfiguration): any{
    let config = this.getPostPutPatchConfig();

    let data = {
      automatic_recording: (typeof seatConfiguration?.automatic_recording === "boolean")
        ? seatConfiguration.automatic_recording
        : true,
      automatic_upload: (typeof seatConfiguration?.automatic_upload === "boolean")
        ? seatConfiguration.automatic_upload
        : true,
      upload_interval: (typeof seatConfiguration?.upload_interval === "number")
        ? seatConfiguration.upload_interval
        : 14400,
      seat_status: 1
    }

    return axios.put(this.casanaCloudApiUrl + 'seatconfig/' + seatId, data, config).then(() => {
      return {
        success: true,
      }
    })
    .catch(function (error) {
      console.error(error);
      return {
        success: false,
      }
    });
  }

  /**
   * Update the Seat entity on the Casana Cloud to the maximum battery charge. This is invoked in the SeatDebug
   * component, and requires the user to physically replace the battery before making this API call.
   *
   * @param seatId
   */
  public resetSeatBattery(seatId: string): any{
    let config = this.getPostPutPatchConfig();

    return axios.post(this.casanaCloudApiUrl + 'seat/replace-battery/' + seatId, [], config).then(() => {
      return {
        success: true,
      }
    })
      .catch(function (error) {
        console.error(error);
        return {
          success: false,
        }
      });
  }

  /**
   * This function should take in a value from the API response for assigned_seats
   * Sample: [{"1459":"F20AB7EC88FB"}] and return the seat_id for the first
   * value returned even if there is multiple assigned/connected seats.
   * @param assignedSeats
   * @return int
   */
  public extractSeatUserDetail(assignedSeatUsers: any, field: string) {

    // TODO: add some defensive programming to make sure if there are no assigned seats or multiple assigned seats
    // TODO: it still works
    if(assignedSeatUsers.length > 0) {
      if ( field === 'id' ) {
        return parseInt(assignedSeatUsers[0]['seat_user_id']);
      } else {
        return assignedSeatUsers[0]['name'];
      }
    }
  }

}
